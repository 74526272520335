import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const PM_API = process.env.REACT_APP_PM_API;
const API_VERSION = '/api/v1';

const useFetch = (url) => {
  const { getAccessTokenSilently } = useAuth0();
  const [status, setStatus] = useState('IDLE');
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const requestOptions = (token) => {
    return {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  useEffect(() => {
    setStatus('FETCHING');
    setIsLoading(true);
    getAccessTokenSilently().then((token) =>
      fetch(`${PM_API + API_VERSION + url}`, requestOptions(token))
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw Error(response.statusText);
        })
        .then((res) => {
          setData(res);
          setStatus('FETCHED');
          setIsLoading(false);
        })
        .catch((error) => {
          setData(error.message);
          setStatus('FETCH_ERROR');
          setIsLoading(false);
        })
    );
  }, [getAccessTokenSilently, url]);

  return { status, data, isLoading };
};

export default useFetch;
