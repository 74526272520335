import React from 'react';

const SortIcon = ({ isSorted, isSortedDesc }) => {
  const style = {
    marginLeft: '5px',
    fontSize: '10px',
  };

  if (isSorted) {
    if (isSortedDesc) {
      return <i className="i-arrow-down icon-xs" style={style} />;
    }
    return <i className="i-arrow-up icon-xs" style={style} />;
  }
  return null;
};

export default SortIcon;
