const rules = {
  basic: {
    static: ['read:basic'],
  },
  foreman: {
    static: ['read:basic'],
  },
  projectManager: {
    static: ['read:basic', 'edit:project-progress', 'read:project-invoices'],
  },
  operationsManager: {
    static: ['read:basic', 'edit:project-progress'],
  },
  salesManager: {
    static: ['read:basic', 'read:sales-reports'],
  },
  sales: {
    static: ['read:basic', 'read:sales-reports'],
  },
  estimator: {
    static: ['read:basic', 'read:sales-reports', 'read:project-invoices'],
  },
  financesManager: {
    static: [
      'read:basic',
      'read:sales-reports',
      'read:project-invoices',
      'read:finances',
    ],
  },
  admin: {
    static: [
      'read:basic',
      'read:sales-reports',
      'upload:sales-reports',
      'edit:quotes-info',
      'edit:project-progress',
      'read:project-invoices',
      'manage:integration',
      'read:finances',
    ],
  },
};

export default rules;
