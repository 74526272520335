import { React } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const PDFViewer = ({
  pdfUrl,
  show,
  toggle,
  next,
  previous,
  listSize,
  index,
}) => (
  <>
    <Modal toggle={toggle} isOpen={show} size="lg" fade={false} centered>
      <ModalHeader toggle={toggle} style={{ padding: '0 1rem' }} />
      <ModalBody
        style={{ textAlign: 'center', margin: '0 auto', padding: '10px' }}
      >
        <embed
          src={pdfUrl}
          style={{ width: '750px', height: '800px' }}
          type="application/pdf"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          size="sm"
          color={index < 1 ? '' : 'info'}
          disabled={index < 1}
          onClick={previous}
        >
          Previous
        </Button>
        <Button
          size="sm"
          color={index < listSize - 1 ? 'info' : ''}
          disabled={index >= listSize - 1}
          onClick={next}
        >
          Next
        </Button>
      </ModalFooter>
    </Modal>
  </>
);

export default PDFViewer;
