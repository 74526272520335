import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { format } from 'date-fns';
import styles from './LocationTable.module.css';

const EmployeeTimesheetRow = ({ timesheet, index, lastElement }) => {
  const classNames = [
    index % 2 ? styles.striped : '',
    index === lastElement ? styles.lastRow : '',
  ];

  const TimesheetStatus = ({ status }) => {
    return (
      <span
        className={`badge ${
          status ? 'badge-success' : 'badge-warning'
        } d-print-none`}
      >
        {status ? 'Approved' : 'Pending'}
      </span>
    );
  };

  return (
    <tr key={`TIMES-${timesheet.id}`} className={classNames.join(' ')}>
      <td className={`${styles.borderOff} d-print-none`} />
      <td align="center" className="d-print-none">
        {timesheet.id}
      </td>
      <td align="center">{format(new Date(timesheet.date), 'dd/MM/yy')}</td>
      <td colSpan="2" style={{ width: '50%' }}>
        <b>
          {`${timesheet.Employee.first_name} ${timesheet.Employee.last_name}`}
        </b>{' '}
        - {timesheet.notes}
      </td>
      <td className={styles.colCenter}>{timesheet.total_time}</td>
      <td className="d-print-none" style={{ textAlign: 'center' }}>
        <TimesheetStatus status={timesheet.time_approved} />
      </td>
    </tr>
  );
};

const AreOfWorkRow = ({ area, toggleChild }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (toggleChild != null) {
      setIsOpen(toggleChild);
    }
  }, [toggleChild]);

  const AreaSummaryRow = ({ className }) => {
    return (
      <tr
        key={`AREA-${area.id}`}
        className={`${`${styles.areaRow} ${className}`}`}
      >
        <td align="center" style={{ width: '80px' }}>
          <Button
            onClick={toggle}
            size="sm"
            color={isOpen ? 'info' : 'primary'}
          >
            {isOpen ? '-' : '+'}
          </Button>
        </td>
        <td className={styles.areaSurmmaryName}>{area.name}</td>
        <td align="center" style={{ width: '200px' }}>
          {area.total_time}
        </td>
        <td />
        <td />
      </tr>
    );
  };

  return (
    <>
      <AreaSummaryRow className="d-print-none" />

      {isOpen && (
        <>
          <tr>
            <td colSpan="8" style={{ padding: 0 }}>
              <Table
                style={{
                  marginBottom: 0,
                  borderTopStyle: 'groove',
                  borderTopColor: '#f5f5f5',
                }}
              >
                <thead>
                  <tr
                    key={`AREA-${area.id}`}
                    className={`${styles.areaRow} d-none d-print-table-row`}
                    style={{
                      borderBottom: '1px solid',
                      backgroundColor: '#c3c3c3',
                    }}
                  >
                    <th colSpan="2" className={styles.areaSurmmaryName}>
                      {area.name}
                    </th>
                    <th />
                    <th style={{ width: '200px', textAlign: 'center' }}>
                      {area.total_time}
                    </th>
                  </tr>
                  <tr className={styles.timesheetsHeader}>
                    <th
                      style={{ width: '5px' }}
                      className={`${styles.borderOff} d-print-none`}
                    />
                    <th
                      className="d-print-none"
                      style={{ textAlign: 'center', width: '50px' }}
                    >
                      Id
                    </th>
                    <th style={{ width: '100px', textAlign: 'center' }}>
                      Date
                    </th>
                    <th style={{ width: '50%' }} />
                    <th />
                    <th className={styles.colCenter}>Hours</th>
                    <th
                      className="d-print-none"
                      style={{ textAlign: 'center' }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {area.Timesheets.map((timesheet, index) => (
                    <EmployeeTimesheetRow
                      key={timesheet.id}
                      timesheet={timesheet}
                      index={index}
                      lastElement={area.Timesheets.length - 1}
                    />
                  ))}
                </tbody>
              </Table>
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default AreOfWorkRow;
