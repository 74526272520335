import React, { useRef, useState } from 'react';
import { useFetch } from 'hooks';
import { useIntl } from 'react-intl';
import QuotesTable from './QuotesTable';
import SalesTeamCRTable from './SalesTeamCRTable';
import WidgetBlock from '../../general/Widgets/WidgetBlock';
import ProgressWidget from '../../general/Widgets/ProgressWidget';
import QuotesSentTable from './QuotesSentTable';

const WidgetSection = ({ isLoading, data, turnAround }) => {
  const intl = useIntl();
  const formatCurrency = (value) =>
    intl.formatNumber(value, {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'AUD',
    });

  const inProgressQuotesRef = useRef();
  const quotesSentRef = useRef();

  const [salesCRTable, setSalesCRTable] = useState(false);

  const { data: salesTeamCR } = useFetch(
    '/sales/dashboard/salesperson/conversion-breakdown'
  );

  const toggleSalesCRTable = () => {
    setSalesCRTable(!salesCRTable);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className="row margin-0">
        <WidgetBlock
          title="Current Week"
          subtitle={data.currentWeek}
          icon="i-calendar"
        />

        <ProgressWidget
          data={[
            { title: 'Fy Complete', progress: data.fyCompleted },
            {
              title: 'Sales Target',
              progress: ((data.salesTotal / 3120000) * 100).toFixed(2),
            },
          ]}
        />

        <WidgetBlock
          title="New Quotes"
          subtitle={`${data.quotesProduced} (${data.quotesUnique})`}
          icon="i-star"
        />

        <WidgetBlock
          title="In Progress"
          subtitle={data.quotesInProgress}
          icon="i-note"
          clicable
          onClick={() => inProgressQuotesRef.current.toggle()}
        />

        <WidgetBlock
          title="Sent"
          subtitle={data.quotesSent}
          icon="i-paper-plane"
          clicable
          onClick={() => quotesSentRef.current.toggle()}
        />

        <WidgetBlock
          title="Rejected"
          subtitle={data.quotesRejected}
          icon="i-envelope"
        />

        <WidgetBlock
          title="Quotes Produced"
          subtitle={formatCurrency(data.quotesTotalThisFY)}
          icon="i-graph"
        />

        <WidgetBlock
          title={`${data.quotesPendingApproval} Pndg Approval`}
          subtitle={formatCurrency(data.quotesTotalPending)}
          icon="i-question"
        />

        <WidgetBlock
          title={`${data.quotesApproved} Approved`}
          subtitle={formatCurrency(data.salesTotal)}
          icon="i-trophy"
        />

        <WidgetBlock
          title="Avg. Weekly Sales"
          subtitle={formatCurrency(data.averageWeeklySales)}
          icon="i-chart"
        />

        <WidgetBlock
          title="Quotes Won Weekly"
          subtitle={data.averageQuotesApprovedWeekly}
          icon="i-reload"
        />

        <WidgetBlock
          title="CR"
          subtitle={intl.formatNumber(data.conversionRate, {
            style: 'percent',
            minimumFractionDigits: 2,
          })}
          icon="i-target"
          clicable
          onClick={toggleSalesCRTable}
        />
      </div>

      <QuotesTable
        header="Quotes In Progress"
        tData={data.inProgress}
        ref={inProgressQuotesRef}
      />

      {!turnAround.isLoading && (
        <QuotesSentTable
          header="Quotes Sent This FY"
          tData={turnAround.data.allTime.quotesSent}
          ref={quotesSentRef}
        />
      )}

      {salesCRTable && (
        <SalesTeamCRTable
          title="Sales Team CR"
          data={salesTeamCR}
          onClick={toggleSalesCRTable}
        />
      )}
    </>
  );
};

export default WidgetSection;
