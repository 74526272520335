import React from 'react';
import { useFetch } from 'hooks';
import { Row, Col } from 'reactstrap';
import WidgetSection from './WidgetSection';

const General = () => {
  const fyStatus = useFetch('/project/dashboard/fy-stats');

  const liveProjects = useFetch('/project/dashboard/live');

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Dashboard</h1>
              </div>
              <div className="float-right">
                <span
                  className="text-muted"
                  style={{
                    fontSize: '15px',
                    padding: '0 15px 0 0',
                    lineHeight: '77px',
                  }}
                >
                  Last updated at{' '}
                  {!fyStatus.isLoading &&
                    new Date(fyStatus.data.updatedAt).toLocaleString()}
                </span>
              </div>
            </div>

            <div className="clearfix" />
            {!liveProjects.isLoading && !fyStatus.isLoading && (
              <WidgetSection
                isLoading={fyStatus.isLoading}
                data={fyStatus.data}
                liveProjects={liveProjects.data}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default General;
