import React from 'react';
import { Input } from 'reactstrap';

const TextFilter = ({ setFilter, accessor, label }) => (
  <Input
    type="text"
    onChange={(e) => {
      setFilter(accessor, e.target.value || undefined);
    }}
    placeholder={label}
  />
);

export default TextFilter;
