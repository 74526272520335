import React from 'react';

import { Table, Spinner } from 'reactstrap';

import { useIntl } from 'react-intl';

const SalesTeamCRTable = ({ data: rows, title, onClick }) => {
  const intl = useIntl();
  const formatCurrency = (value) =>
    intl.formatNumber(value, {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'AUD',
    });

  const getContent = () => {
    if (!rows) {
      return (
        <span>
          <Spinner animation="grow" size="sm" role="status" /> Loading ...
        </span>
      );
    }

    return (
      <Table responsive className="fixheight text-center">
        <thead>
          <tr>
            <th>Rep</th>
            <th>Unique Quotes</th>
            <th>$ Quoted</th>
            <th>Approved Quotes</th>
            <th>$ Approved</th>
            <th>CR</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={`${row.salesPerson}`}>
              <td>{row.salesPerson}</td>
              <td>{row.uniqueQuotesQty}</td>
              <td>{formatCurrency(row.totalExcUnique)}</td>
              <td>{row.approvedQuotesQty}</td>
              <td>{formatCurrency(row.totalExcApproved)}</td>
              <td>{row.conversionRate}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <section className="box ">
      <header className="panel_header">
        <h2 className="title float-left">{title}</h2>
        <button type="button" className="close" onClick={onClick}>
          <span>×</span>
        </button>
      </header>
      <div className="content-body">
        <div className="row">
          <div className="col-12">{getContent()}</div>
        </div>
      </div>
    </section>
  );
};

export default SalesTeamCRTable;
