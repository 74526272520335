import React, { useMemo, useState, useEffect } from 'react';
import { Table, Tooltip, Button } from 'reactstrap';
import { useTable, useSortBy, useRowState, useExpanded } from 'react-table';
import Can from 'components/Can';

import {
  SortIcon,
  CustomTextCell,
  CurrencyCell,
} from 'components/sales/QuotesTable/Table';
import RowEditProgress from './RowEditProgress';
import RowArchiveLocation from './RowArchiveLocation';
import ProgressCell from './ProgressCell';
import ProjectsHoursTable from './liveProjectsTable/ProjectHoursTable';
import ProjectExpensesTable from './liveProjectsTable/ProjectExpensesTable';

const BillableHoursLeft = ({ data }) => {
  const [ttProjectsOpen, setTooltipOpen] = useState(false);
  const [billableProjects, setBillableProjects] = useState(data);

  useEffect(() => {
    const exclusiveSubstatus = [
      'Works Complete on Site',
      'Works Ready for Final Invoice',
      'Waiting for Final Payment',
    ];
    const filteredList = data.filter(
      (x) => exclusiveSubstatus.includes(x.substatus) !== true
    );
    setBillableProjects(filteredList);
  }, [data]);

  const toggle = () => setTooltipOpen(!ttProjectsOpen);

  const Compute = () => {
    if (!Array.isArray(billableProjects)) {
      return 0;
    }
    const hoursLeft = billableProjects.reduce(
      (prev, cur) => prev + Number(cur.billableHoursLeft),
      0
    );
    return hoursLeft.toFixed(2);
  };
  return (
    <>
      <div className="col-12">
        <Tooltip
          placement="top"
          isOpen={ttProjectsOpen}
          target="toolTipProjects"
          toggle={toggle}
        >
          Excludes &apos;Works Complete on Site&apos;, &apos;Works Ready for
          Final Invoice&apos;, &apos;Waiting for Final Payment&apos;
        </Tooltip>
        <span className="font-weight-bold" id="toolTipProjects">
          Total Projects:
        </span>{' '}
        {billableProjects.length}
      </div>
      <div className="col-12">
        <span className="font-weight-bold">Billable Hours Left:</span>{' '}
        <Compute />
      </div>
    </>
  );
};

const DataTable = ({ header, tData = [], toggle }) => {
  const buildHeader = () => {
    const columns = [
      {
        Header: '',
        id: 'expander',
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            <Button
              size="sm"
              color={row.isExpanded ? 'danger' : 'info'}
              className="btn-icon"
            >
              <i
                className={
                  row.isExpanded ? 'i-minus icon-xs' : 'i-plus icon-xs'
                }
              >
                {' '}
              </i>
            </Button>
          </span>
        ),
      },
      {
        Header: 'JN',
        accessor: 'jobNumber',
      },
      {
        Header: 'Project',
        accessor: 'taskName',
        className: 'text-left',
      },
      {
        Header: 'Client',
        accessor: 'client',
        className: 'text-left',
      },
      {
        Header: 'Substatus',
        accessor: 'substatus',
        Cell: CustomTextCell,
      },
      {
        Header: 'Progress %',
        accessor: 'pmProgress',
        Cell: ProgressCell,
        isEditable: true,
      },
      {
        Header: 'Claimed',
        accessor: 'progressClaimed',
        Cell: ProgressCell,
      },
      {
        Header: 'Hrs Left',
        accessor: 'hoursLeft',
      },
      {
        Header: 'Hrs Roster',
        accessor: 'rosteredHours',
      },
      {
        Header: 'Hrs Used %',
        accessor: 'hoursBurnDown',
        Cell: ProgressCell,
        progressColour: true,
      },
      {
        Header: 'Mat Left',
        accessor: 'materialLeft',
        Cell: CurrencyCell,
      },
      {
        Header: 'Mat Used %',
        accessor: 'expBurnDown',
        Cell: ProgressCell,
        progressColour: true,
      },
    ];

    if (Can('edit:project-progress')) {
      columns.push({
        Header: '',
        accessor: 'taskId',
        Cell: RowEditProgress,
      });
    }
    if (Can('manage:integration')) {
      columns.push({
        Header: '',
        accessor: 'locationId',
        Cell: RowArchiveLocation,
      });
    }

    return columns;
  };

  const columns = useMemo(buildHeader, []);

  const data = tData;

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        <ProjectsHoursTable row={row.original} />
        <ProjectExpensesTable row={row.original} />
      </>
    ),
    []
  );

  const { headerGroups, rows, prepareRow, visibleColumns } = useTable(
    { columns, data },
    useSortBy,
    useRowState,
    useExpanded
  );

  return (
    <div className="row margin-0">
      <div className="col-12">
        <section className="box ">
          <header className="panel_header">
            {header && <h2 className="title float-left">{header}</h2>}
            <button type="button" className="close" onClick={() => toggle()}>
              <span>×</span>
            </button>
          </header>
          <div className="content-body">
            <div className="row">
              <div className="col-12">
                <Table striped responsive className="text-center">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps([
                              {
                                className: column.className,
                                style: column.style,
                              },
                              column.getSortByToggleProps(),
                            ])}
                          >
                            {column.render('Header')}
                            <SortIcon
                              isSorted={column.isSorted}
                              isSortedDesc={column.isSortedDesc}
                            />
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <>
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td
                                style={{ padding: '0 5px' }}
                                className={
                                  cell.column.className
                                    ? cell.column.className
                                    : ''
                                }
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>

                          {row.isExpanded ? (
                            <tr>
                              <td
                                colSpan={visibleColumns.length}
                                style={{ padding: '0 5px' }}
                              >
                                {renderRowSubComponent({ row })}
                              </td>
                            </tr>
                          ) : null}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <BillableHoursLeft data={tData} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const ProjectsTable = ({ header, tData, toggle }) => {
  if (tData.length) {
    return <DataTable header={header} tData={tData} toggle={toggle} />;
  }
  return null;
};

export default ProjectsTable;
