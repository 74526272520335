import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

import _ from 'lodash';

const PM_API = process.env.REACT_APP_PM_API;

const RowArchiveLocation = ({ row }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isArchiving } = row.state;
  const [isSaving, setSaving] = useState(false);
  const [isArchived, setArchived] = useState(null);

  useEffect(() => {
    if (row.values !== undefined) {
      if (isArchived !== false) {
        setArchived(!row.original.locationActive);
      }
    }
  }, []);

  const requestOptions = (token) => ({
    credentials: 'include',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  const saveData = (locationId) => {
    setSaving(true);
    getAccessTokenSilently().then((token) =>
      fetch(
        `${PM_API}/api/v1/deputy/archive/${locationId}`,
        requestOptions(token, {})
      )
        .then((res) => res.json())
        .catch((error) => console.log('Error:', error))
        .finally(() => {
          setSaving(false);
        })
    );
  };

  const edit = () => {
    const data = _.clone(row.values);
    row.setState({ isArchiving: true, isSaving: false, data });
  };

  const save = () => {
    row.setState((currState) => {
      const newState = currState;
      newState.isArchiving = false;
      setArchived(true);
      saveData(newState.data.locationId);
      return newState;
    });
  };

  if (isSaving) {
    return (
      <Button size="sm" color="info" className="btn-icon">
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Button>
    );
  }

  if (isArchived) {
    return <></>;
  }

  if (!isArchiving) {
    return (
      <Button size="sm" color="danger" className="btn-icon" onClick={edit}>
        Archive
      </Button>
    );
  }
  return (
    <Button size="sm" color="success" onClick={save}>
      Save
    </Button>
  );
};

export default RowArchiveLocation;
