import React, { useState } from 'react';
import { useIntl } from 'react-intl';

const ProgressColour = (pct) => {
  const percentColors = [
    { pct: 0.0, color: { r: 163, g: 204, b: 121 } },
    { pct: 0.5, color: { r: 251, g: 217, b: 119 } },
    { pct: 1.0, color: { r: 237, g: 111, b: 111 } },
  ];

  let idx = 1;
  for (let i = 1; i < percentColors.length - 1; i += 1, idx += 1) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  const lower = percentColors[idx - 1];
  const upper = percentColors[idx];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return color;
};

const ProgressCell = (props) => {
  const { value, row, column } = props;
  const isEditingRow = row.state.isEditing;
  const [progressValue, setProgress] = useState(value);
  const intl = useIntl();

  if (isEditingRow !== true || column.isEditable !== true) {
    if (value === null) {
      return '-';
    }
    const pct = progressValue / 100;

    const outputValue = intl.formatNumber(pct, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (column.progressColour) {
      const isLight = (color) => {
        const R = parseInt(color.r, 16);
        const G = parseInt(color.g, 16);
        const B = parseInt(color.b, 16);
        const brightness = (R * 299 + G * 587 + B * 114) / 1000;
        return brightness > 155;
      };

      const color = ProgressColour(pct);
      const textColor = isLight(color) ? '#000000' : '#ffffff';
      return (
        <span
          style={{
            backgroundColor: `rgb(${[color.r, color.g, color.b].join(',')})`,
            padding: '10px',
            color: textColor,
            fontWeight: 400,
            display: 'block',
            margin: '0 auto',
          }}
        >
          {outputValue}
        </span>
      );
    }

    return outputValue;
  }

  const onChange = (e) => setProgress(e.target.value);

  const handleChange = (el) => {
    setProgress(el.target.value);
    row.setState((currState) => {
      const newState = currState;
      newState.data[column.id] = el.target.value;
      return newState;
    });
  };

  return (
    <input
      type="number"
      step="0.01"
      min="0"
      max="100"
      value={progressValue}
      onChange={onChange}
      onBlur={handleChange}
    />
  );
};

export default ProgressCell;
