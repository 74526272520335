import React from 'react';
import { useIntl } from 'react-intl';

const Summarize = ({ cols, rows }) => {
  const intl = useIntl();

  const convertToCurrency = (value) =>
    intl.formatNumber(value, {
      style: 'currency',
      currency: 'AUD',
    });

  const sum = (values, accessor) =>
    values.reduce((prev, cur) => prev + Number(cur.values[accessor]), 0);

  const filterNull = (values, accessor) =>
    values.filter((x) => x.values[accessor] !== null);

  const avg = (values, accessor) =>
    (sum(values, accessor) / values.length).toFixed(2);

  const compute = () => {
    for (let i = 0; i < cols.length; i += 1) {
      const col = cols[i];
      col.value = '';
      if (col.method === 'sum') {
        col.value = sum(rows, col.accessor);
        col.value = convertToCurrency(col.value);
      } else if (col.method === 'count') {
        col.value = rows.length;
      } else if (col.method === 'avg') {
        col.value = avg(rows, col.accessor);
      } else if (col.method === 'avgNotNull') {
        const values = filterNull(rows, col.accessor);
        col.value = avg(values, col.accessor);
      }
    }
    return cols;
  };

  const data = compute();

  return data.map((x) => (
    <div className="row">
      <div className="col-12">
        <div className="float-right">
          <span className="font-weight-bold">{x.label}:</span> {x.value}
        </div>
      </div>
    </div>
  ));
};

export default Summarize;
