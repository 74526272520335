import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';

import Can from 'components/Can';

const PM_API = process.env.REACT_APP_PM_API;

const EditableCell = ({ row, field }) => {
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const [value, setValue] = useState(row[field]);

  const { getAccessTokenSilently } = useAuth0();

  const toggleEditing = () => {
    if (!isSaving && !isEditing) {
      setEditing(true);
    }
  };

  const requestOptions = (token, data) => ({
    credentials: 'include',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const saveData = (data) => {
    setSaving(true);
    getAccessTokenSilently().then((token) =>
      fetch(
        `${PM_API}/api/v1/project/update/contractors/total-hours`,
        requestOptions(token, data)
      )
        .then((res) => res.json())
        .catch((error) => console.log('Error:', error))
        .finally(() => {
          setSaving(false);
          setEditing(false);
        })
    );
  };

  const save = () => {
    saveData({
      jobNumber: row.jobNumber,
      hours: value,
    });
  };

  if (!Can('edit:project-progress')) {
    return <>{value}</>;
  }

  if (!isEditing) {
    return (
      <>
        {value} &nbsp;
        <Button
          size="sm"
          color="info"
          className="btn-icon"
          onClick={toggleEditing}
        >
          <i className="i-pencil icon-xs"> </i>
        </Button>
      </>
    );
  }

  if (isSaving) {
    return (
      <Button size="sm" color="info" className="btn-icon">
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Button>
    );
  }

  return (
    <>
      <input
        type="number"
        step="0.01"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button size="sm" color="success" className="btn-icon" onClick={save}>
        <i className="i-cloud-upload icon-xs"> </i>
      </Button>
    </>
  );
};

export default EditableCell;
