import React, { useState, forwardRef } from 'react';
import { format } from 'date-fns';
import { Button } from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const DateCell = (props) => {
  const { value, row, column } = props;
  const isEditingRow = row.state.isEditing;
  const [date, setDate] = useState(new Date(value));

  if (isEditingRow !== true || column.isEditable !== true) {
    if (value === null) {
      return '-';
    }
    return format(date, 'dd/MM/yy');
  }

  const CustomInput = forwardRef(({ value: newValue, onClick }, ref) => (
    <Button size="sm" color="primary" onClick={onClick} ref={ref}>
      {newValue}
    </Button>
  ));

  const handleChange = (newDate) => {
    setDate(newDate);
    row.setState((currState) => {
      const newState = currState;
      newState.data[column.id] = newDate;
      return newState;
    });
  };

  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      selected={date}
      onChange={handleChange}
      customInput={<CustomInput />}
    />
  );
};

export default DateCell;
