import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  FormFeedback,
  Alert,
} from 'reactstrap';

const ReportUpload = () => {
  const { register, handleSubmit, errors, formState } = useForm();
  const [sucessMessage, setSuccessMessage] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const PM_API = process.env.REACT_APP_PM_API;

  const requestOptions = (token, formData) => {
    return {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
  };

  const onSubmit = (data, e) => {
    const formData = new FormData();
    formData.append('myFile', data.myFile[0]);

    getAccessTokenSilently().then((token) =>
      fetch(`${PM_API}/api/v1/sales/report`, requestOptions(token, formData))
        .then(() => {
          setSuccessMessage(true);
          e.target.reset();
          setTimeout(() => setSuccessMessage(false), 2000);
        })
        .catch((error) => console.log('Error:', error)),
    );
  };
  return (
    <div>
      <div className="content">
        <Col xs={12} md={12}>
          <div className="page-title">
            <div className="float-left">
              <h1 className="title">Upload CSV File</h1>
            </div>
          </div>
          <div className="row margin-0">
            <div className="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
              <section className="box">
                <header className="panel_header">
                  <h2 className="title float-left">Locate File</h2>
                </header>
                <div className="content-body">
                  <Form
                    action="https://localhost:3002/api/v1/sales/report"
                    method="POST"
                    encType="multipart/form-data"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormGroup>
                      <Label>File</Label>
                      <Input
                        type="file"
                        name="myFile"
                        innerRef={register({ required: true })}
                        valid={!errors.myFile}
                        invalid={errors.myFile}
                      />
                      <FormFeedback>Please select the file first.</FormFeedback>
                    </FormGroup>
                    <Alert color="success" hidden={!sucessMessage}>
                      Success: upload complete!
                    </Alert>
                    <Button type="submit" disabled={formState.isSubmitting}>
                      Submit
                    </Button>
                  </Form>
                </div>
              </section>
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default ReportUpload;
