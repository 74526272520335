import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Table, Col } from 'reactstrap';
import { useTable, useRowState, useFilters, useSortBy } from 'react-table';

import {
  SortIcon,
  DateCell,
  CurrencyCell,
  CustomTextCell,
  LinkCell,
} from 'components/sales/QuotesTable/Table';

import {
  SelectFilter,
  TextFilter,
} from 'components/general/Table/Filter/Filters';

import Summarize from 'components/general/Table/Custom/Summarize';

const DataTable = forwardRef(({ header, tData = [] }, ref) => {
  const buildHeader = () => {
    const columns = [
      {
        Header: '#',
        accessor: 'jobNumber',
      },
      {
        Header: 'Week',
        accessor: 'week',
        filter: 'equals',
      },
      {
        Header: 'Rep',
        accessor: 'salesPerson',
      },
      {
        Header: 'Created',
        accessor: 'createdDate',
        Cell: DateCell,
      },
      {
        Header: 'Sent On',
        accessor: 'quoteSentOn',
        Cell: DateCell,
      },
      { Header: 'Turn Days', accessor: 'turnDays' },
      {
        Header: 'Type',
        accessor: 'taskType',
      },
      {
        Header: 'Name',
        accessor: 'taskName',
        Cell: LinkCell,
      },
      {
        Header: 'Status',
        accessor: 'status',
        filter: 'equals',
      },
      {
        Header: 'Substatus',
        accessor: 'substatus',
        Cell: CustomTextCell,
        filter: 'equals',
      },
      {
        Header: '$',
        accessor: 'totalExc',
        Cell: CurrencyCell,
      },
    ];

    return columns;
  };

  const columns = useMemo(buildHeader, []);

  const data = useMemo(() => {
    if (tData.length) {
      return tData;
    }
    return new Array(columns.length);
  }, [tData, columns.length]);

  const { headerGroups, rows, prepareRow, setFilter } = useTable(
    {
      columns,
      data,
    },
    useRowState,
    useFilters,
    useSortBy
  );

  return (
    <div className="row margin-0">
      <div className="col-12">
        <section className="box ">
          <header className="panel_header">
            {header && <h2 className="title float-left">{header}</h2>}
            <button
              type="button"
              className="close"
              onClick={() => ref.current.toggle()}
            >
              <span>×</span>
            </button>
          </header>
          <div className="content-body">
            <div className="row">
              <div className="col-12">
                <div className="row mb-2">
                  <Col md={1}>
                    <SelectFilter
                      setFilter={setFilter}
                      data={tData}
                      label="Week"
                      accessor="week"
                    />
                  </Col>
                  <Col md={2}>
                    <SelectFilter
                      setFilter={setFilter}
                      data={tData}
                      label="Sales Person"
                      accessor="salesPerson"
                    />
                  </Col>
                  <Col md={2}>
                    <SelectFilter
                      setFilter={setFilter}
                      data={tData}
                      label="Types"
                      accessor="taskType"
                    />
                  </Col>
                  <Col md={1}>
                    <SelectFilter
                      setFilter={setFilter}
                      data={tData}
                      label="Status"
                      accessor="status"
                    />
                  </Col>
                  <Col md={2}>
                    <SelectFilter
                      setFilter={setFilter}
                      data={tData}
                      label="Substatus"
                      accessor="substatus"
                    />
                  </Col>
                  <Col md={4}>
                    <TextFilter
                      setFilter={setFilter}
                      accessor="taskName"
                      label="Job Name"
                    />
                  </Col>
                </div>
                <Table striped responsive className="text-center">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render('Header')}
                            <SortIcon
                              isSorted={column.isSorted}
                              isSortedDesc={column.isSortedDesc}
                            />
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td
                              style={{ padding: '0 5px' }}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Summarize
                  rows={rows}
                  cols={[
                    { accessor: 'jobNumber', method: 'count', label: 'Count' },
                    {
                      accessor: 'totalExc',
                      method: 'sum',
                      label: 'Total ex GST',
                    },
                    {
                      accessor: 'turnDays',
                      method: 'avg',
                      label: 'Avg Days to Send',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
});

const QuotesSentTable = forwardRef(
  ({ header, tData, editable = false }, ref) => {
    const [visible, setVisible] = useState(true);

    useImperativeHandle(ref, () => ({
      toggle: () => {
        setVisible(!visible);
      },
    }));

    if (visible) {
      return (
        <DataTable
          header={header}
          tData={tData}
          editable={editable}
          ref={ref}
        />
      );
    }
    return null;
  }
);

export default QuotesSentTable;
