import React, { useMemo } from 'react';
import { Input } from 'reactstrap';

const SelectFilter = ({ setFilter, accessor, label, data }) => {
  const values = useMemo(() => {
    if (data.length) {
      const list = new Set(data.map((x) => x[accessor]));
      return [...list.values()].sort((a, b) => {
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      });
    }
    return [];
  }, [data, accessor]);

  return (
    <Input
      type="select"
      onChange={(e) =>
        setFilter(
          accessor,
          e.target.value === 'all' ? undefined : e.target.value
        )
      }
    >
      <option value="all">{label}</option>
      {values.map((x) => (
        <option key={x}>{x}</option>
      ))}
    </Input>
  );
};

export default SelectFilter;
