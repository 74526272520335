import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import history from './utils/history';

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const audience = process.env.REACT_APP_AUTH0_ADMIN_API_AUDIENCE;

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    audience={audience}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens
  >
    <IntlProvider locale="en-AU">
      <App />
    </IntlProvider>
  </Auth0Provider>,
  document.getElementById('root')
);
