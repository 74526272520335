import React from 'react';
import { Table } from 'reactstrap';

import EditableCell from './Contractor/EditableCell';

const ProjectsHoursTable = ({ row }) => {
  if (row) {
    return (
      <>
        <h2>Project Hours Breakdown</h2>
        <Table>
          <thead>
            <tr>
              <th>Allowed</th>
              <th>PM</th>
              <th>Overtime</th>
              <th>Rostered</th>
              <th>Left</th>
              <th>Contractors</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{row.estimatedTotalHours}</td>
              <td>{row.actualTotalHours}</td>
              <td>{row.overtimeHours}</td>
              <td>{row.rosteredHours}</td>
              <td>{row.hoursLeft}</td>
              <td>
                <EditableCell row={row} field="contractorsTotalHours" />
              </td>
            </tr>
          </tbody>
        </Table>
      </>
    );
  }
  return null;
};

export default ProjectsHoursTable;
