import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

import Button from 'components/general/CustomButton/CustomButton';

const CardSocials = (props) => {
  const { socials } = props;

  return (
    <div className="button-container">
      {socials.socials.map((prop) => {
        return (
          <Button color="neutral" icon round size={props.size} href={prop.link}>
            <i className={prop.icon} />
          </Button>
        );
      })}
    </div>
  );
};

CardSocials.defaultProps = {
  size: 'sm',
  socials: null,
};

CardSocials.propTypes = {
  // size of all social buttons
  size: PropTypes.oneOf(['sm', 'lg']),
  // example: [{icon: "name of icon", href="href of icon"},...]
  socials: PropTypes.arrayOf(PropTypes.object),
};

export default CardSocials;
