import React, { useState, useRef } from 'react';
import { Button } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';

import AreaOfWorkTable from 'views/project/location/AreaOfWorkTable';

const LocationRow = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChildOpen, setChildOpen] = useState(null);
  const ref = useRef();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleChildren = () => {
    setChildOpen(!isChildOpen);
  };

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  return (
    <>
      <tr key={`LOC-${row.id}`}>
        <td>
          <Button
            onClick={toggle}
            size="sm"
            color={isOpen ? 'default' : 'secondary'}
          >
            {isOpen ? '-' : '+'}
          </Button>
        </td>
        <td align="center">{row.id}</td>
        <td className="user-inline-img" style={{ textAlign: 'center' }}>
          {row.deputy_code}
        </td>
        <td>{row.deputy_address}</td>
        <td align="center">{row.total_time}</td>
        <td className="d-print-none" style={{ textAlign: 'center' }}>
          <span
            className={`btn btn-sm ${
              row.active ? 'btn-success' : 'btn-danger'
            }`}
          >
            {row.active ? 'Open' : 'Closed'}
          </span>
          {isOpen && (
            <>
              <Button
                type="button"
                className="btn btn-default btn-sm ml-1"
                onClick={handlePrint}
              >
                <i className="i-printer" />
              </Button>
              <Button
                type="button"
                onClick={toggleChildren}
                className="btn btn-default btn-sm ml-1"
              >
                <i className={isChildOpen ? 'i-minus' : 'i-plus'} />
              </Button>
            </>
          )}
        </td>
      </tr>
      {isOpen && (
        <AreaOfWorkTable
          companyId={row.id}
          isOpen={isOpen}
          toggleChild={isChildOpen}
          row={row}
          ref={ref}
        />
      )}
    </>
  );
};

export default LocationRow;
