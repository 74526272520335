import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Spinner,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
} from 'reactstrap';

import LocationTable from 'views/project/location/LocationTable';

import { useAuth0 } from '@auth0/auth0-react';

const PM_API = process.env.REACT_APP_PM_API;

function Location() {
  const [locationsDb, setLocationsDb] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showActiveLocations, setShowActiveLocations] = useState(true);
  const [search, setSearch] = useState();

  const { getAccessTokenSilently } = useAuth0();

  const requestOptions = (token) => {
    return {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      fetch(`${PM_API}/api/v1/locations`, requestOptions(token))
        .then((res) => res.json())
        .then((response) => {
          setLocationsDb(response);
          setIsLoading(false);
        })
        .catch((error) => console.log('Error:', error))
    );
  }, [getAccessTokenSilently]);

  useEffect(() => {
    let loc;
    if (search) {
      loc = locationsDb.filter(
        (item) =>
          item.deputy_address.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    } else {
      loc = locationsDb.filter((item) => item.active === showActiveLocations);
    }
    setLocations(loc);
  }, [locationsDb, showActiveLocations, search]);

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Dashboard</h1>
              </div>
            </div>

            <div className="row margin-0">
              <div className="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <section className="box">
                  <header className="panel_header">
                    <h2 className="title float-left">Locations</h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        <span className={isLoading ? '' : 'd-none'}>
                          <>
                            <Spinner animation="grow" size="sm" role="status" />{' '}
                            Loading ...
                          </>
                        </span>
                        <Row className={isLoading ? 'd-none' : ''}>
                          <Col xs={12} md={2}>
                            <FormGroup>
                              <Input
                                type="select"
                                defaultValue="true"
                                onChange={(e) =>
                                  setShowActiveLocations(
                                    e.target.value === 'active'
                                  )
                                }
                              >
                                <option value="active">Active</option>
                                <option value="archived">Archived</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs={12} md={{ size: 2, offset: 8 }}>
                            <InputGroup className="topbar-search open">
                              <InputGroupAddon addonType="append">
                                <i className="i-magnifier" />
                              </InputGroupAddon>
                              <Input
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <LocationTable
                          locations={locations}
                          className={isLoading ? 'd-none' : ''}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Location;
