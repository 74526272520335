import React from 'react';

const WidgetBlock = ({
  icon,
  title,
  subtitle,
  onClick,
  iconSize = 'icon-lg',
  iconColor = 'icon-primary',
  clicable = false,
}) => (
  <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
    <div
      className="db_box iconbox"
      style={{ cursor: clicable ? 'pointer' : null }}
    >
      <div className="widdata" onClick={onClick} role="presentation">
        <i className={`widicon ${icon} ${iconSize} ${iconColor}`} />
        <h3 className="widtitle">{title}</h3>
        <div className="widtag">{subtitle}</div>
      </div>
    </div>
  </div>
);

export default WidgetBlock;
