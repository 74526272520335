import React, { useRef } from 'react';
import { Row, Col } from 'reactstrap';
import { useFetch, useDimension } from 'hooks';
import TurnaroundTable from './TurnaroundTable';

import BarChart from './BarChart';
import WidgetSection from './WidgetSection';

const General = () => {
  const turnaroundTableRef = useRef();
  const turnATableHeight = useDimension(turnaroundTableRef).height;

  const fyStatus = useFetch('/sales/dashboard/fy-stats');
  const { data: fyQuotesSeries } = useFetch('/sales/dashboard/fy-quotes');
  const turnAround = useFetch('/sales/dashboard/turnaround');

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Dashboard - FY2020/2021</h1>
              </div>
              <div className="float-right">
                <span
                  className="text-muted"
                  style={{
                    fontSize: '15px',
                    padding: '0 15px 0 0',
                    lineHeight: '77px',
                  }}
                >
                  Last updated at{' '}
                  {!fyStatus.isLoading &&
                    new Date(fyStatus.data.updatedAt).toLocaleString()}
                </span>
              </div>
            </div>

            <div className="clearfix" />

            <WidgetSection
              isLoading={fyStatus.isLoading}
              data={fyStatus.data}
              turnAround={turnAround}
            />

            <div className="row margin-0">
              <div className="col-12 col-lg-7 col-xl-8 col-md-7">
                <BarChart
                  title="Weekly Quotes"
                  data={fyQuotesSeries}
                  height={turnATableHeight}
                />
              </div>

              <div className="col-12 col-lg-5 col-md-5 col-xl-4">
                <TurnaroundTable
                  data={turnAround.data}
                  ref={turnaroundTableRef}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default General;
