import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Col } from 'reactstrap';

const ReportUpload = () => {
  const { user } = useAuth0();

  return (
    <div>
      <div className="content">
        <Col xs={12} md={12}>
          <div className="page-title">
            <div className="float-left" />
          </div>
          <div className="row margin-0">
            <div className="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
              <section className="box">
                <div className="content-body">
                  <header className="panel_header">
                    <p className="title float-left">
                      Welcome <b>{user.name}</b>
                    </p>
                  </header>
                </div>
              </section>
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default ReportUpload;
