import React from 'react';

const isLight = (color) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
};

const CustomTextCell = ({ value, row }) => {
  if (!row.original.colour) {
    return value;
  }

  const textColor = isLight(row.original.colour) ? '#000000' : '#ffffff';
  return (
    <span
      style={{
        backgroundColor: row.original.colour,
        padding: '10px',
        color: textColor,
        fontWeight: 400,
        display: 'block',
        margin: '0 auto',
      }}
    >
      {value}
    </span>
  );
};
export default CustomTextCell;
