import React, { useState, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav } from 'reactstrap';

import Can from 'components/Can';
import Navmenudropdown from 'components/general/Navmenudropdown/Navmenudropdown';
import Navmenugroup from 'components/general/Navmenudropdown/Navmenugroup';

// javascript plugin used to create scrollbars on windows
import logofull from 'assets/img/logo-full.png';
import logomini from 'assets/img/logo-mini.png';
import logofulldark from 'assets/img/logo-full-dark.png';
import logominidark from 'assets/img/logo-mini-dark.png';

const Sidebar = (props) => {
  const [opendd, setOpendd] = useState('');
  const [currentmenu, setCurrentMenu] = useState('notset');
  const { routes } = props;
  const sideBarRef = useRef();
  const location = useLocation();

  const handleOpendd = (open) => {
    setCurrentMenu('');
    if (opendd === open) {
      setOpendd('');
    } else {
      setOpendd(open);
    }
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) =>
    location.pathname.indexOf(routeName) > -1 ? ' active' : '';

  const children = (child, parent) => {
    const links = [];
    if (child) {
      for (let i = 0; i < child.length; i += 1) {
        if (Can(child[i].scope)) {
          links.push(
            <li key={i}>
              <NavLink
                to={child[i].path}
                className="nav-link"
                activeClassName="active"
              >
                <span>{child[i].name}</span>
              </NavLink>
            </li>
          );
          if (location.pathname === child[i].path) {
            if (currentmenu === 'notset' && opendd === '') {
              setCurrentMenu(parent);
            }
          }
        }
      }
      return <Nav>{links}</Nav>;
    }
    return <></>;
  };

  return (
    <div className="sidebar menubar" data-color="black">
      <div className="logo">
        <a href="/" className="logo-mini">
          <div className="logo-img">
            <img src={logomini} alt="react-logo" className="light-logo" />
            <img src={logominidark} alt="react-logo" className="dark-logo" />
          </div>
        </a>
        <a href="/" className="logo-full">
          <img src={logofull} alt="react-logo" className="light-logo" />
          <img src={logofulldark} alt="react-logo" className="dark-logo" />
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sideBarRef}>
        <Nav className="navigation">
          {routes.map((prop) => {
            if (prop.hide) return null;
            if (prop.redirect) return null;
            if (prop.type === 'child') return null;
            if (Can(prop.scope) === false) return null;
            if (prop.type === 'navgroup')
              return <Navmenugroup name={prop.name} />;
            if (prop.type === 'dropdown')
              return (
                <li
                  key={`${prop.parentid}-${prop.path}`}
                  className={`${prop.parentid} ${
                    (prop.parentid === currentmenu &&
                      prop.parentid !== '' &&
                      prop.parentid !== 'multipurpose') ||
                    opendd === prop.name
                      ? 'active'
                      : ''
                  } nav-parent `}
                  data-toggle="collapse"
                >
                  <a
                    to={prop.path}
                    className="nav-link"
                    onClick={() => handleOpendd(prop.name)}
                    href="#!"
                  >
                    <i className={`i-${prop.icon}`} />
                    <p>{prop.name}</p>
                    <span className="badge">{prop.badge}</span>
                    <span className="arrow i-arrow-left" />
                  </a>
                  {children(prop.child, prop.parentid)}
                </li>
              );

            if (prop.type === 'dropdown-backup')
              return (
                <Navmenudropdown
                  name={prop.name}
                  icon={prop.icon}
                  path={prop.path}
                  badge={prop.badge}
                  child={prop.child}
                  openclass={opendd === prop.name ? 'activethis' : ''}
                  onClick={() => handleOpendd(prop.name)}
                />
              );
            return (
              <li
                key={prop.path}
                className={`${activeRoute(prop.path)} nav-parent `}
              >
                <NavLink
                  to={prop.path}
                  className="nav-link"
                  activeClassName="active"
                  onClick={() => handleOpendd(prop.name)}
                >
                  <i className={`i-${prop.icon}`} />
                  <p>{prop.name}</p>
                  <span className="badge">{prop.badge}</span>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
