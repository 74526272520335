import GeneralLayout from 'layouts/General';

const BASEDIR = process.env.REACT_APP_BASEDIR;

const indexRoutes = [
  {
    path: `${BASEDIR}/`,
    name: 'Index',
    component: GeneralLayout,
    public: false,
  },
  { path: '/', name: 'Index', component: GeneralLayout, public: false },
];

export default indexRoutes;
