import React from 'react';

const ProgressBar = ({ title, progress }) => (
  <>
    <h3 className="widtitle">{title}</h3>
    <div className="widtag">
      <div className="progress mb-2">
        <span
          className={progress < 50 ? 'text-dark' : 'text-light'}
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
            marginTop: '-2px',
          }}
        >
          {progress}%
        </span>
        <span
          className="progress-bar progress-bar-primary"
          aria-valuenow="62"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  </>
);

const ProgressWidget = ({
  icon = null,
  data,
  onClick,
  iconSize = 'icon-lg',
  iconColor = 'icon-primary',
  clicable = false,
}) => (
  <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6">
    <div
      className="db_box iconbox"
      style={{ cursor: clicable ? 'pointer' : null }}
    >
      <div className="widdata" onClick={onClick} role="presentation">
        {icon && <i className={`widicon ${icon} ${iconSize} ${iconColor}`} />}
        {data.map((el) => (
          <ProgressBar
            key={el.title + el.progress}
            title={el.title}
            progress={el.progress}
          />
        ))}
      </div>
    </div>
  </div>
);

export default ProgressWidget;
