import React, { useState, useRef, useEffect } from 'react';
import {
  Collapse,
  Navbar,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap';

import { useAuth0 } from '@auth0/auth0-react';

const APP_ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

const Header = (props) => {
  const [userddOpen, setUserddOpen] = useState(false);

  const sidebarToggle = useRef();

  const { user, logout } = useAuth0();

  const userddToggle = () => setUserddOpen(!userddOpen);

  const openSidebar = () => {
    document.documentElement.classList.toggle('nav-toggle');
    sidebarToggle.current.classList.toggle('toggled');
  };

  useEffect(() => {
    if (props.navtype === 'mini') {
      document.documentElement.classList.add('nav-toggle');
      sidebarToggle.current.classList.add('toggled');
    } else {
      document.documentElement.classList.remove('nav-toggle');
      sidebarToggle.current.classList.remove('toggled');
    }
  }, []);

  return (
    <Navbar expand="lg" className="navbar-absolute fixed-top">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={openSidebar}
            >
              <i className="i-menu" />
            </button>
          </div>
        </div>

        <Collapse navbar className="navbar-right">
          <Nav navbar>
            <Dropdown
              nav
              isOpen={userddOpen}
              toggle={() => userddToggle()}
              className="userdd"
            >
              <DropdownToggle caret nav>
                <img
                  src={user.picture}
                  alt="react-logo"
                  className="avatar-image"
                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem header>{user.name}</DropdownItem>
                <DropdownItem
                  tag="a"
                  className=""
                  onClick={() => logout({ returnTo: APP_ADMIN_URL })}
                >
                  <i className="i-lock" /> Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
