import { useIntl } from 'react-intl';

const CurrencyCell = ({ value }) => {
  const intl = useIntl();
  return intl.formatNumber(value, {
    style: 'currency',
    currency: 'AUD',
  });
};
export default CurrencyCell;
