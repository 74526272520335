import React from 'react';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

import _ from 'lodash';

const PM_API = process.env.REACT_APP_PM_API;

const RowEditCell = ({ row }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isEditing } = row.state;

  const requestOptions = (token, data) => {
    return {
      credentials: 'include',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
  };

  const saveData = (data) => {
    getAccessTokenSilently().then((token) =>
      fetch(`${PM_API}/api/v1/quote/update`, requestOptions(token, data))
        .then((res) => res.json())
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log('Error:', error)),
    );
  };

  const edit = () => {
    const data = _.clone(row.values);
    row.setState({ isEditing: true, data });
  };

  const save = () => {
    row.setState((currState) => {
      const newState = currState;
      newState.isEditing = false;
      if (_.isEqual(newState.data, row.values) === false) {
        saveData(newState.data);
      }
      return newState;
    });
  };

  if (!isEditing) {
    return (
      <Button size="sm" color="info" className="btn-icon" onClick={edit}>
        <i className="i-pencil icon-xs"> </i>
      </Button>
    );
  }
  return (
    <Button size="sm" color="success" onClick={save}>
      Save
    </Button>
  );
};

export default RowEditCell;
