import React, { forwardRef, useState, useEffect } from 'react';
import { format } from 'date-fns';

import { Table, Spinner } from 'reactstrap';

const TurnaroundTable = forwardRef(({ data }, ref) => {
  const [lastWeek, setLastWeek] = useState();
  const [thisMonth, setThisMonth] = useState();
  const [allTime, setAllTime] = useState();

  useEffect(() => {
    if (data) {
      if (data.lastWeek) {
        setLastWeek(data.lastWeek);
      }
      if (data.currentMonth) {
        setThisMonth(data.currentMonth);
      }
      if (data.allTime) {
        setAllTime(data.allTime);
      }
    }
  }, [data]);

  const getContent = () => {
    if (data === undefined || allTime === undefined) {
      return (
        <span>
          <Spinner animation="grow" size="sm" role="status" /> Loading ...
        </span>
      );
    }

    return (
      <Table responsive className="fixheight text-center">
        <thead>
          <tr>
            <th> </th>
            <th>
              Last Week
              <br />
              <small>
                {format(new Date(lastWeek.firstDay), 'dd/MM')} to{' '}
                {format(new Date(lastWeek.lastDay), 'dd/MM')}
              </small>
            </th>
            <th>
              <span>{format(new Date(), 'MMMM')}</span>
            </th>
            <th>Current FY</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Min</th>
            <td>{lastWeek.min}</td>
            <td>{thisMonth.min}</td>
            <td>{allTime.min}</td>
          </tr>
          <tr>
            <th>25%</th>
            <td>{lastWeek.q1}</td>
            <td>{thisMonth.q1}</td>
            <td>{allTime.q1}</td>
          </tr>
          <tr>
            <th>50%</th>
            <td>{lastWeek.q2}</td>
            <td>{thisMonth.q2}</td>
            <td>{allTime.q2}</td>
          </tr>
          <tr>
            <th>75%</th>
            <td>{lastWeek.q3}</td>
            <td>{thisMonth.q3}</td>
            <td>{allTime.q3}</td>
          </tr>
          <tr>
            <th>Max</th>
            <td>{lastWeek.max}</td>
            <td>{thisMonth.max}</td>
            <td>{allTime.max}</td>
          </tr>
          <tr>
            <th>Avg</th>
            <td>{lastWeek.avg}</td>
            <td>{thisMonth.avg}</td>
            <td>{allTime.avg}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <section className="box ">
      <header className="panel_header">
        <h2 className="title float-left">Turnaround time</h2>
      </header>
      <div className="content-body">
        <div className="row margin-0" ref={ref}>
          <div className="col-12">{getContent()}</div>
        </div>
      </div>
    </section>
  );
});

export default TurnaroundTable;
