import { useAuth0 } from '@auth0/auth0-react';
import rulesList from '../rbac-rules';

/** To-Do: Replace rulesList from File with call to Auth Api */

const namespace = process.env.REACT_APP_AUTH0_NAMESPACE;

const Check = (action, data) => {
  const { user } = useAuth0();

  if (user == null) return false;

  const userRoles = user[`${namespace}/roles`];
  let performAction = action;

  if (!action) {
    performAction = 'read:basic';
  }

  // If the user has no permission set in Auth0 he gets basic access
  if (userRoles.length === 0) {
    userRoles.push('basic');
  }

  const permissions = {
    static: [],
  };

  for (let i = 0; i < userRoles.length; i += 1) {
    const permission = rulesList[userRoles[i]];
    permissions.static = permissions.static.concat(permission.static);
  }

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(performAction)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[performAction];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export default Check;
