import React from 'react';
import { Col, Spinner } from 'reactstrap';
import { useFetch } from 'hooks';

const XeroCallback = () => {
  const params = new URLSearchParams(window.location.search);
  const { isLoading, status, data } = useFetch(
    `/xero/callback?${params.toString()}`
  );

  if (isLoading) {
    return (
      <>
        <Spinner animation="grow" size="sm" role="status" /> Loading ...
      </>
    );
  }

  return (
    <div>
      <div className="content">
        <Col xs={12} md={12}>
          <div className="page-title">
            <div className="float-left" />
          </div>
          <div className="row margin-0">
            <div className="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
              <section className="box">
                <div className="content-body">
                  <header className="panel_header">
                    <p className="title float-left">
                      {status === 'FETCH_ERROR' ? (
                        <>{data}</>
                      ) : (
                        'Xero Connected Sucessefully!'
                      )}
                    </p>
                  </header>
                </div>
              </section>
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default XeroCallback;
