import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';

const BarChart = ({ data, title, height = 308 }) => {
  const [barChartData, setBarChatData] = useState();
  const intl = useIntl();

  const parseCurrencyLabel = (value) => {
    return intl.formatNumber(value, {
      style: 'currency',
      currency: 'AUD',
    });
  };

  useEffect(() => {
    if (data) {
      const quotesQtd = Object.values(data).map((row) => row[0]);
      const quotesTotalEx = Object.values(data).map((row) => row[1]);
      setBarChatData({
        labels: Object.keys(data),
        datasets: [
          {
            label: 'Quotes Created',
            backgroundColor: 'rgba(129, 199, 132, 0.3)',
            borderColor: 'rgba(38, 166, 154,0.8)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(129, 199, 132, 0.5)',
            hoverBorderColor: 'rgba(38, 166, 154,1)',
            data: quotesQtd,
            yAxisID: 'y-axis-1',
            order: 2,
          },
          {
            label: 'Weekly Estimates',
            backgroundColor: 'rgba(0, 174, 239, 0.0)',
            borderColor: 'rgba(0, 174, 239, 1)',
            borderWidth: 1,
            hoverBorderColor: 'rgba(0, 174, 239, 1)',
            data: quotesTotalEx,
            type: 'line',
            yAxisID: 'y-axis-2',
            order: 1,
          },
        ],
      });
    }
  }, [data]);

  const options = {
    legend: {
      display: true,
      position: 'bottom',
    },
    scales: {
      xAxes: [
        {
          display: 1,
          gridLines: {
            color: 'rgba(0, 0, 0, 0.01)',
          },
          ticks: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          id: 'y-axis-1',
          categoryPercentage: 0.7,
          barPercentage: 0.5,
          maxBarThickness: 11,
          display: true,
          gridLines: {
            color: 'rgba(0, 0, 0, 0.01)',
          },
          ticks: {
            display: true,
          },
        },
        {
          id: 'y-axis-2',
          position: 'right',
          type: 'linear',
          display: true,
          ticks: {
            callback(value, index, values) {
              return parseCurrencyLabel(value);
            },
          },
        },
      ],
    },
    tooltips: {
      mode: 'index',
      intersect: true,
      callbacks: {
        label(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          if (label === 'Weekly Estimates: ') {
            label += parseCurrencyLabel(tooltipItem.yLabel);
          } else {
            label += Math.round(tooltipItem.yLabel * 100) / 100;
          }
          return label;
        },
      },
    },
    maintainAspectRatio: false,
  };

  const getContent = () => {
    if (data === undefined || barChartData === undefined || height <= 23) {
      return (
        <span>
          <Spinner animation="grow" size="sm" role="status" /> Loading ...
        </span>
      );
    }

    return <Bar data={barChartData} options={options} height={height} />;
  };

  return (
    <section className="box ">
      <header className="panel_header">
        <h2 className="title float-left">{title}</h2>
      </header>
      <div className="content-body">
        <div className="row">
          <div className="col-12">
            <div className="chart-container" style={{ height: `${height}px` }}>
              <div className="chart-area">{getContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarChart;
