import React from 'react';
import { Table } from 'reactstrap';
import LocationRow from 'views/project/location/LocationRow';

const LocationTable = ({ locations, playlistCharts, className }) => (
  <>
    <Table className={className} responsive>
      <thead>
        <tr>
          <th style={{ width: '60px' }} />
          <th style={{ textAlign: 'center', width: '50px' }}>Id</th>
          <th style={{ width: '80px', textAlign: 'center' }}>Job</th>
          <th style={{ width: '52.5%' }}>Address</th>
          <th style={{ textAlign: 'center' }}>Hours</th>
          <th style={{ textAlign: 'center' }}>Status</th>
        </tr>
      </thead>
      <tbody>
        {locations.map((row) => (
          <LocationRow key={row.id} row={row} playlistCharts={playlistCharts} />
        ))}
      </tbody>
    </Table>
  </>
);

export default LocationTable;
