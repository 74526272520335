import Home from 'views/home';
import Location from 'views/project/location/Location';
import SalesDashboard from 'views/sales/dashboard/General';
import ProjectsDashboard from 'views/project/dashboard/Project';
import ProjectsHistory from 'views/project/history/Projects';
import ReportUpload from 'views/sales/admin/ReportUpload';
import General from 'views/general/Dashboard/General';
import XeroCallback from 'views/admin/xeroCallback';

const BASEDIR = process.env.REACT_APP_BASEDIR;

const dashRoutes = [
  {
    path: `${BASEDIR}/home`,
    component: Home,
    name: 'Home',
    icon: 'home',
    parentid: 'home',
  },

  {
    path: '#',
    name: 'Projects',
    icon: 'folder-alt',
    type: 'dropdown',
    parentid: 'project',
    child: [
      { path: `${BASEDIR}/dashboard`, name: 'Dashboard' },
      { path: `${BASEDIR}/project-locations`, name: 'Deputy Locations' },
      { path: `${BASEDIR}/project-history`, name: 'Project History' },
    ],
  },

  {
    path: `${BASEDIR}/dashboard`,
    component: ProjectsDashboard,
    type: 'child',
  },
  {
    path: `${BASEDIR}/project-history`,
    component: ProjectsHistory,
    type: 'child',
  },
  {
    path: `${BASEDIR}/xero-callback`,
    component: XeroCallback,
    name: 'Callback',
    hide: true,
  },

  {
    path: `${BASEDIR}/project-locations`,
    component: Location,
    type: 'child',
  },

  {
    path: '#',
    name: 'Sales',
    icon: 'speedometer',
    type: 'dropdown',
    parentid: 'sales',
    scope: 'read:sales-reports',
    hide: false,
    child: [
      {
        path: `${BASEDIR}/sales/admin/upload-report`,
        name: 'Upload Report',
        scope: 'upload:sales-reports',
      },
      {
        path: `${BASEDIR}/sales/dashboard`,
        name: 'Dashboard',
        scope: 'read:sales-reports',
      },
    ],
  },

  {
    path: `${BASEDIR}/sales/admin/upload-report`,
    component: ReportUpload,
    type: 'child',
    public: false,
    scope: 'upload:sales-reports',
  },

  {
    path: `${BASEDIR}/sales/dashboard`,
    component: SalesDashboard,
    type: 'child',
    scope: 'read:sales-reports',
  },
  {
    path: `${BASEDIR}/dashboard`,
    component: General,
    type: 'child',
    scope: 'read:basic',
  },

  {
    redirect: true,
    path: `${BASEDIR}/`,
    pathTo: `${BASEDIR}/home`,
    name: 'Projects',
  },
  {
    redirect: true,
    path: '/',
    pathTo: `${BASEDIR}/home`,
    name: 'Projects',
  },
];
export default dashRoutes;
