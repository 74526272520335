import React from 'react';
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Header, Footer, Sidebar } from 'components';

import dashboardRoutes from 'routes/general';
import {
  topbarStyle,
  menuStyle,
  menuType,
  topbarType,
  navWidth,
} from 'variables/settings/general';

import PrivateRoute from 'components/PrivateRoute';

import Can from 'components/Can';

const GeneralLayout = () => {
  // componentDidUpdate(e) {
  //   if (e.history.action === 'PUSH') {
  //     this.refs.mainPanel.scrollTop = 0;
  //     document.scrollingElement.scrollTop = 0;
  //   }
  // };

  const topbarColor = topbarStyle;
  const topbarType2 = topbarType;
  const menuColor = menuStyle;
  const menuType2 = menuType;

  return (
    <div
      className="wrapper"
      data-menu={menuColor}
      data-topbar={topbarColor}
      data-menutype={menuType2}
      data-topbartype={topbarType2}
    >
      <Header navtype={navWidth} admintype="crm" />
      <Sidebar routes={dashboardRoutes} admintype="crm" />
      <div className="main-panel">
        <Switch>
          {dashboardRoutes.map((prop) => {
            if (prop.redirect) {
              return (
                <Redirect key={prop.path} from={prop.path} to={prop.pathTo} />
              );
            }
            if (prop.public) {
              return (
                <Route
                  key={prop.path}
                  path={prop.path}
                  component={prop.component}
                />
              );
            }
            if (Can(prop.scope)) {
              return (
                <PrivateRoute
                  key={prop.path}
                  path={prop.path}
                  component={prop.component}
                />
              );
            }
            return null;
          })}
        </Switch>
        <Footer fluid />
      </div>
    </div>
  );
};

export default GeneralLayout;
