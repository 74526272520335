import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Table } from 'reactstrap';
import { useTable, useSortBy, useRowState } from 'react-table';
import Can from 'components/Can';

import {
  RowEditCell,
  SortIcon,
  DateCell,
  CurrencyCell,
  CustomTextCell,
} from 'components/sales/QuotesTable/Table';

const DataTable = forwardRef(
  ({ header, tData = [], editable = false }, ref) => {
    const buildHeader = () => {
      const columns = [
        {
          Header: '#',
          accessor: 'jobNumber',
        },
        {
          Header: 'Created',
          accessor: 'createdDate',
          Cell: DateCell,
        },
        {
          Header: 'Sent On',
          accessor: 'quoteSentOn',
          Cell: DateCell,
        },
        {
          Header: 'Type',
          accessor: 'taskType',
        },
        {
          Header: 'Sales Person',
          accessor: 'salesPerson',
        },
        {
          Header: 'Estimator',
          accessor: 'estimator',
        },
        {
          Header: 'Name',
          accessor: 'taskName',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'Substatus',
          accessor: 'substatus',
          Cell: CustomTextCell,
        },
        {
          Header: '$',
          accessor: 'totalExc',
          Cell: CurrencyCell,
        },
      ];

      if (editable && Can('edit:quotes-info')) {
        columns.push({
          Header: '',
          accessor: 'id',
          Cell: RowEditCell,
        });
      }
      if (tData.length && tData[0].statusDate) {
        columns.splice(8, 0, {
          Header: `${tData[0].status} Date`,
          accessor: 'statusDate',
          Cell: DateCell,
          isEditable: true,
        });
      }
      return columns;
    };

    const columns = useMemo(buildHeader, []);

    const data = useMemo(() => {
      if (tData.length) {
        return tData;
      }
      return new Array(columns.length);
    }, [tData, columns.length]);

    const { headerGroups, rows, prepareRow } = useTable(
      { columns, data },
      useSortBy,
      useRowState
    );

    return (
      <div className="row margin-0">
        <div className="col-12">
          <section className="box ">
            <header className="panel_header">
              {header && <h2 className="title float-left">{header}</h2>}
              <button
                type="button"
                className="close"
                onClick={() => ref.current.toggle()}
              >
                <span>×</span>
              </button>
            </header>
            <div className="content-body">
              <div className="row">
                <div className="col-12">
                  <Table striped responsive className="text-center">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render('Header')}
                              <SortIcon
                                isSorted={column.isSorted}
                                isSortedDesc={column.isSortedDesc}
                              />
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td
                                style={{
                                  padding: '0 5px',
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
);

const QuotesTable = forwardRef(({ header, tData, editable = false }, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    toggle: () => {
      setVisible(!visible);
    },
  }));

  if (visible) {
    return (
      <DataTable header={header} tData={tData} editable={editable} ref={ref} />
    );
  }
  return null;
});

export default QuotesTable;
